export const DOCUMENT_FORM = 'DocumentForm'

export const FIELD_TEXT = 'text'
export const FIELD_PHONE = 'phone'
export const FIELD_EMAIL = 'email'
export const FIELD_DATE = 'date'
export const FIELD_DATE_PICKER = 'date_picker'
export const FIELD_PASSWORD = 'password'
export const FIELD_SELECT = 'select'
export const FIELD_RADIO = 'radio'
export const FIELD_TEXTAREA = 'textarea'
export const FIELD_SCALE = 'scale'
export const FIELD_HIDDEN = 'hidden'
export const FIELD_CHECKBOX = 'checkbox'
export const FIELD_FILES = 'files'
export const FIELD_UPLOADER = 'file_uploader'
export const VALUE_HABITATION_APARTMENT = 'apartment'

export const FIELDS = [FIELD_SELECT, FIELD_RADIO, FIELD_TEXTAREA, FIELD_SCALE]

export const VERIFY_CHECKING_CONDITION_FORM = 'VERIFY_CHECKING_CONDITION_FORM'

export const GENDER_M = 'Mr'
export const GENDER_MME = 'Mrs'

export const RESERVES_FORM = 'RESERVES_FORM'
export const RESERVES_FIELD = 'reservesField'
export const WITHOUT_RESERVES = 'withoutReserves'
export const WITH_RESERVES = 'withReserves'
export const RESERVE_TYPE = 'reserveType'
export const RESERVES = 'reserves'
