import requiresAuth from 'hoc/security/requiresAuth'
import asyncComponent from 'utils/asyncComponent'
import translateFactory from 'utils/translateFactory'

const TrackingPage = asyncComponent(() =>
  import(/* webpackChunkName: "TrackingPage" */ 'pages/trackingPage'),
)

const templatePage = asyncComponent(() =>
  import(/* webpackChunkName: "TrackingTemplate" */ 'pages/templatePage'),
)

const HomePage = asyncComponent(() =>
  import(
    /* webpackChunkName: "TrackingPage" */ 'pages/homePage/HomePageContainer'
  ),
)

const LoginPage = asyncComponent(() =>
  import(
    /* webpackChunkName: "TrackingAuthenticationPage" */ 'pages/loginPage'
  ),
)

const FaqPage = asyncComponent(() =>
  import(/* webpackChunkName: "TrackingAuthenticationPage" */ 'pages/faqPage'),
)

const NotFoundPage = asyncComponent(() =>
  import(/* webpackChunkName: "TrackingNotFoundPage" */ 'pages/notFoundPage'),
)

const getTrackingRoutes = translate => {
  const trackingPagePath = translate('routes.track', {
    id: ':id',
  })
  const trackingAuthenticationPagePath = translate(
    'routes.track_authentication',
  )

  return [
    {
      exact: true,
      path: trackingPagePath,
      component: requiresAuth(TrackingPage, '/login'),
    },
    {
      exact: true,
      path: '/login',
      component: LoginPage,
    },
    {
      exact: true,
      path: '/faq/:id',
      component: requiresAuth(FaqPage, '/login'),
    },
    {
      path: '/',
      component: requiresAuth(HomePage, '/login'),
      exact: true,
    },
    {
      exact: true,
      path: '/template',
      component: templatePage,
    },
    { path: '*', component: NotFoundPage },
  ]
}

const createRoutes = ({ translate, lang, origin }) => [
  ...getTrackingRoutes(translate),
]

export default (lang, origin) =>
  createRoutes({ translate: translateFactory(lang), lang, origin })
