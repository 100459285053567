// JobPro statuses
export const STATUS_PENDING_ANSWER = 'pending_answer'
export const STATUS_JOB_ACCEPTED = 'job_accepted'
export const STATUS_JOB_DECLINED = 'job_declined'

// Job statuses
export const STATUS_PENDING_REALIZATION = 'pending_realization'
export const STATUS_PENDING_START = 'pending_start'
export const STATUS_PENDING_NEW_DATE = 'pending_new_date'
export const STATUS_JOB_IN_PROGRESS = 'job_in_progress'
export const STATUS_PENDING_PAYMENT = 'pending_payment'
export const STATUS_PENDING_RESCHEDULING = 'pending_rescheduling'
export const STATUS_PENDING_CANCELATION = 'pending_cancelation'
export const STATUS_JOB_CANCELED = 'job_canceled'
export const STATUS_JOB_DONE = 'job_done'
export const STATUS_JOB_PENDING_START = 'pending_start'
export const STATUS_JOB_PENDING_DATA = 'pending_data'
export const MOBILE_WIDTH_BREAKPOINT = 1224

export const FILTER_DATE_HO = 'minDateHo'
export const FILTER_DATE_PRO = 'minDatePro'
export const STATUS_ORDER_DEFAULT = 'ASC'

export const DATE_INTERVENTION_STATUSES = [
  STATUS_PENDING_START,
  STATUS_JOB_IN_PROGRESS,
  STATUS_PENDING_REALIZATION,
  STATUS_PENDING_RESCHEDULING,
]

export const JOB_PERCEIVED_STATUSES = [
  STATUS_PENDING_ANSWER,
  STATUS_PENDING_REALIZATION,
  STATUS_JOB_IN_PROGRESS,
  STATUS_PENDING_PAYMENT,
  STATUS_JOB_DONE,
  STATUS_JOB_DECLINED,
  STATUS_JOB_CANCELED,
]

export const PICTURE_ORIGIN_CLIENT_RESERVES = 'ho_receipt_reserve'
export const PICTURE_ORIGIN_JOB_TRACKING = 'ho_job_tracking'
export const PICTURE_ORIGIN_CLIENT = 'ho'

export const JOB_PICTURE_ORIGINS = {
  HO: [
    PICTURE_ORIGIN_CLIENT,
    PICTURE_ORIGIN_JOB_TRACKING,
    PICTURE_ORIGIN_CLIENT_RESERVES,
  ],
  PRO: ['checkin', 'realization', 'checkout'],
}

export const FILE_UPLOAD_LIMIT = 10
export const FILE_TYPE_ACCEPTED = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/bmp',
  'application/pdf',
]

export const JOB_STATUS_ORDER = {
  [STATUS_PENDING_ANSWER]: FILTER_DATE_HO,
  [STATUS_PENDING_REALIZATION]: FILTER_DATE_PRO,
  [STATUS_JOB_IN_PROGRESS]: FILTER_DATE_PRO,
  [STATUS_PENDING_PAYMENT]: FILTER_DATE_PRO,
  [STATUS_PENDING_START]: FILTER_DATE_PRO,
  [STATUS_JOB_DONE]: FILTER_DATE_PRO,
  [STATUS_JOB_DECLINED]: FILTER_DATE_HO,
  [STATUS_JOB_CANCELED]: FILTER_DATE_HO,
}

export const VIRTUAL_STATUS_IN_PROGRESS = 'in_progress'
export const VIRTUAL_STATUS_HISTORICAL = 'historical'
export const VIRTUAL_STATUS_MISSED = 'missed'
export const VIRTUAL_STATUS_BLOCKED = 'blocked_receipt'

const STATUSES_FOR_VIRTUAL_STATUS_MISSED = [
  STATUS_JOB_DECLINED,
  STATUS_PENDING_CANCELATION,
  STATUS_JOB_CANCELED,
]

export const VIRTUAL_STATUSES = {
  [VIRTUAL_STATUS_IN_PROGRESS]: [
    STATUS_PENDING_REALIZATION,
    STATUS_JOB_IN_PROGRESS,
    STATUS_PENDING_PAYMENT,
    STATUS_PENDING_START,
  ],
  [VIRTUAL_STATUS_HISTORICAL]: [
    STATUS_JOB_DONE,
    ...STATUSES_FOR_VIRTUAL_STATUS_MISSED,
  ],
  [VIRTUAL_STATUS_MISSED]: STATUSES_FOR_VIRTUAL_STATUS_MISSED,
  [STATUS_PENDING_REALIZATION]: [
    STATUS_PENDING_REALIZATION,
    STATUS_PENDING_RESCHEDULING,
  ],
  [STATUS_JOB_IN_PROGRESS]: [
    STATUS_PENDING_START,
    STATUS_JOB_IN_PROGRESS,
    VIRTUAL_STATUS_BLOCKED,
  ],
}

export const statusesName = {
  [STATUS_PENDING_ANSWER]: 'engine.job.status.pending_assignment',
  [VIRTUAL_STATUS_IN_PROGRESS]: 'engine.job.status.in_progress',
  [STATUS_PENDING_START]: 'engine.job.status.pending_start',
  [STATUS_PENDING_REALIZATION]: 'engine.job.status.pending_realization',
  [STATUS_JOB_IN_PROGRESS]: 'engine.job.status.job_in_progress',
  [STATUS_PENDING_PAYMENT]: 'engine.job.status.pending_payment',
  [VIRTUAL_STATUS_HISTORICAL]: 'job.list.status.historical',
  [STATUS_JOB_DONE]: 'job.details.status.job_done',
  [STATUS_JOB_DECLINED]: 'job.list.status.job_declined',
  [STATUS_PENDING_CANCELATION]: 'job.list.status.job_canceled',
  [STATUS_JOB_CANCELED]: 'job.list.status.job_canceled',
  [VIRTUAL_STATUS_MISSED]: 'job.details.status.missed',
  [VIRTUAL_STATUS_BLOCKED]: 'job.list.status.job_blocked_receipt',
}

export const statusesNameOnDetails = {
  [STATUS_PENDING_ANSWER]: 'job.details.status.job_to_do',
  [STATUS_PENDING_REALIZATION]: 'job.card.status.pending_realization',
  [STATUS_JOB_IN_PROGRESS]: 'job.card.status.job_in_progress',
  [STATUS_JOB_PENDING_START]: 'engine.job.status.pending_start',
  [STATUS_PENDING_RESCHEDULING]: 'job.card.status.pending_rescheduling',
  [STATUS_PENDING_PAYMENT]: 'job.card.status.pending_payment',
  [STATUS_PENDING_START]: 'job.card.status.pending_start',
  [STATUS_JOB_DONE]: 'job.card.status.job_done',
  [STATUS_PENDING_CANCELATION]: 'job.details.status.job_canceled',
  [STATUS_JOB_CANCELED]: 'job.details.status.job_canceled',
  [STATUS_JOB_DECLINED]: 'job.details.status.job_declined',
  [VIRTUAL_STATUS_BLOCKED]: 'job.card.status.job_blocked_receipt',
}

export const emptyListLabels = {
  [STATUS_PENDING_ANSWER]: 'job.list.empty.pending_answer',
  [STATUS_PENDING_REALIZATION]: 'job.list.empty.pending_realization',
  [STATUS_JOB_IN_PROGRESS]: 'job.list.empty.job_in_progress',
  [STATUS_PENDING_PAYMENT]: 'job.list.empty.pending_payment',
  [STATUS_JOB_DONE]: 'job.list.empty.job_done',
  [VIRTUAL_STATUS_HISTORICAL]: 'job.list.empty.other',
}

export const rangeHoursName = {
  all_the_day: 'engine.job.timeslot.range_hours.all_the_day',
  '12h_to_14h': 'engine.job.timeslot.range_hours.12h_to_14h',
}

export const JOB_ACCEPT_TRANSITION = 'JOB_ACCEPT_TRANSITION'
export const JOB_DECLINE_TRANSITION = 'JOB_DECLINE_TRANSITION'
export const JOB_CLIENT_STEPPER_TRANSITION = 'JOB_CLIENT_STEPPER_TRANSITION'
export const JOB_CLIENT_FORM = 'JOB_CLIENT_FORM'

export const POP_IN_PRO_JOB_DECLINE_REASON_DISPLAY_FLAG =
  'proJobDeclineReasonPopinDisplayed'
export const PRO_JOB_DECLINE_REASON_FORM_NAME = 'proJobDeclineReasonForm'
export const JOBS_PER_PAGE = 6
export const JOBS_PER_PAGE_PENDING_START = 60

export const HTTP_BAD_REQUEST = 400
export const HTTP_CONFLICT_CODE = 409

export const RECEIPT_SIGNATURE_STATE_FINISHED = 'finished'
export const RECEIPT_SIGNATURE_STATE_MANUAL = 'manual'
export const RECEIPT_SIGNATURE_STATE_BLOCKED = 'blocked'
export const RECEIPT_SIGNATURE_STATE_ACTIVE = 'active'
export const RECEIPT_SIGNATURE_STATE_REFUSED = 'refused'
export const AVAILABLE_RECEIPT_SIGNATURE_STATUS = [
  RECEIPT_SIGNATURE_STATE_FINISHED,
  RECEIPT_SIGNATURE_STATE_MANUAL,
  RECEIPT_SIGNATURE_STATE_BLOCKED,
]
export const AVAILABLE_SIGNATURE_TO_CLIENT = [
  RECEIPT_SIGNATURE_STATE_ACTIVE,
  RECEIPT_SIGNATURE_STATE_REFUSED,
]

export const DRAFT_STATUS = 'draft'
export const ACTIVE_STATUS = 'active'
export const EXPIRED_STATUS = 'expired'

export const INVOICE_STATE_NEW = 'new'
export const INVOICE_STATE_AWAITING_VALIDATION = 'awaiting_validation'
export const INVOICE_STATE_AWAITING_PAYMENT = 'awaiting_payment'
export const INVOICE_STATE_VALIDATED = 'validated'
export const INVOICE_STATE_PAID = 'paid'
export const INVOICE_STATE_CONTESTED = 'contested'
export const INVOICE_STATE_MANUAL = 'manual'
export const INVOICE_TRANSITION_CONTEST = 'contest'
export const SIGNATURE_STATE_DONE = 'done'
export const SIGNATURE_STATE_REFUSED = 'refused'

export const CHECK_IN_HO_ERROR_TEXT = 'text'
export const CHECK_IN_HO_ERROR_PRO_EMAIL = 'pro_email'
export const CHECK_IN_HO_ERROR_SHOP_PHONE = 'shop_phone'

export const CHECK_IN_HO_ERRORS = [
  CHECK_IN_HO_ERROR_TEXT,
  CHECK_IN_HO_ERROR_PRO_EMAIL,
  CHECK_IN_HO_ERROR_SHOP_PHONE,
]

export const CODE_PRODUCT_AVAILABLE = 'product_available'
export const CODE_PRODUCT_CONDITION = 'product_condition'
export const PRODUCT_CODES = [CODE_PRODUCT_AVAILABLE, CODE_PRODUCT_CONDITION]
export const CODE_PICTURES_SENT = 'pictures_sent'
export const CODE_JOB_LOCATION_ACCESSIBILITY = 'job_location_accessibility'
export const CODE_HO_PRESENCE_ON_JOB_DAY = 'ho_presence_on_job_day'

export const CHECK_IN_HO_STATUS_OK = 'OK'
export const CHECK_IN_HO_STATUS_KO = 'KO'
export const CHECK_IN_HO_STATUS_NA = 'N/A'
export const checkInConditions = {
  product_available: {
    id: 1,
    name: CODE_PRODUCT_AVAILABLE,
    label: 'tracking.intervention_condition.one.text',
    isBold: true,
    disabled: false,
    bullInfo: [
      'tracking.intervention_condition.one.tooltip_one',
      'tracking.intervention_condition.one.tooltip_two',
    ],
    error: CHECK_IN_HO_ERROR_TEXT,
  },
  product_condition: {
    id: 2,
    name: CODE_PRODUCT_CONDITION,
    label: 'tracking.intervention_condition.two.text',
    isBold: true,
    disabled: true,
    bullInfo: [
      'tracking.intervention_condition.two.tooltip_one',
      'tracking.intervention_condition.two.tooltip_two',
    ],
    error: CHECK_IN_HO_ERROR_TEXT,
  },
  pictures_sent: {
    id: 3,
    name: CODE_PICTURES_SENT,
    isBold: false,
    disabled: false,
    label: 'tracking.intervention_condition.three.text',
    bullInfo: ['tracking.intervention_condition.three.tooltip'],
    error: CHECK_IN_HO_ERROR_PRO_EMAIL,
  },
  job_location_accessibility: {
    id: 4,
    name: CODE_JOB_LOCATION_ACCESSIBILITY,
    isBold: false,
    disabled: false,
    label: 'tracking.intervention_condition.four.text',
    bullInfo: ['tracking.intervention_condition.four.tooltip'],
    error: CHECK_IN_HO_ERROR_SHOP_PHONE,
  },
  ho_presence_on_job_day: {
    id: 5,
    name: CODE_HO_PRESENCE_ON_JOB_DAY,
    isBold: false,
    disabled: false,
    label: 'tracking.intervention_condition.five.text',
    bullInfo: ['tracking.intervention_condition.five.tooltip'],
    error: CHECK_IN_HO_ERROR_SHOP_PHONE,
  },
}

export const productCheckInConditions = {
  product_available: {
    id: 1,
    name: CODE_PRODUCT_AVAILABLE,
    label: 'checkin.product.one.text',
    isBold: false,
    disabled: false,
    bullInfo: [
      'tracking.intervention_condition.one.tooltip_one',
      'tracking.intervention_condition.one.tooltip_two',
    ],
    error: CHECK_IN_HO_ERROR_TEXT,
  },
  product_condition: {
    id: 2,
    name: CODE_PRODUCT_CONDITION,
    label: 'checkin.product.two.text',
    isBold: false,
    disabled: false,
    bullInfo: [
      'tracking.intervention_condition.two.tooltip_one',
      'tracking.intervention_condition.two.tooltip_two',
    ],
    error: CHECK_IN_HO_ERROR_TEXT,
  },
}

// Job Partners
export const TRACKING_QUOTATIS_THEME = 'quotatis'
export const TRACKING_LM_THEME = 'LM'
export const TRACKING_BOULANGER_THEME = 'boulanger'
const PARTNERS_ID = {
  BOULANGER_SAV: 7,
  ECOMMERCE: 6,
}
export const PARTNERS_USING_OVERRIDDEN_MIN_DATE = [
  PARTNERS_ID.BOULANGER_SAV,
  PARTNERS_ID.ECOMMERCE,
]
