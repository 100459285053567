import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TagManager from 'react-gtm-module'
import { google, trackingLocales } from 'config'
import pushGtmEvent from 'utils/gtm'

const GoogleTagManagerView = ({
  jobId = undefined,
  gtmId,
  locale,
  baseUrl,
}) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId,
      dataLayerName: 'dataLayer',
      dataLayer: { jobId },
    }

    TagManager.initialize(tagManagerArgs)

    if (trackingLocales[locale].url === baseUrl) {
      pushGtmEvent('js', new Date())
      pushGtmEvent('config', google.gtagTrackPose)
    }
  }, [])

  return null
}

GoogleTagManagerView.propTypes = {
  jobId: PropTypes.string,
  gtmId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
}

export default GoogleTagManagerView
