import { keyBy, pick, reduce } from 'lodash'

import { VIRTUAL_STATUSES } from 'constants/job'

import { initialState } from './selectors'
import {
  JOBS_REQ,
  SET_ALL_JOBS_IS_LOADING,
  SET_CURRENT_JOB_ID,
  GET_JOB,
  UPDATE_JOB,
  GET_RECEIPT_JOB,
  VALIDATE_RECEIPT,
  GET_RECEIPT_SIGNATURE,
  GET_INVOICE_JOB,
  TRANSITION_INVOICE_JOB,
  GET_JOBS_CUSTOMER,
  GET_JOB_CUSTOMER,
  GET_JOB_TOKEN_FIREBASE,
  SET_CURRENT_GEOLOC_PRO,
  ESTIMATED_HOUR_MAP,
  GET_CHECKING_CONDITION_LIST,
  GET_PRO_PHONE_NUMBER,
  SET_TRACKING_FILL_IN_HO_INFORMATION_FORM_ERROR,
  UPLOAD_HO_PICTURE_TO_CLOUDINARY,
  DELETE_JOB_PICTURE,
  MULTIPLE_UPLOAD_HO_FILE,
  GET_RECEIPT_TYPES,
  SEND_RESERVES,
  GET_RECEIPT_WITH_RESERVES,
  OPEN_YOUSIGN_IS_LOADING,
  HOW_TO_TAKE_PICTURES,
  GET_HELP_PHONE,
} from './actions'
import {
  addDays,
  dateDiffInDays,
  getExcludedDates,
  getMaxDate,
  getNextWorkDay,
} from 'utils/date'

const sumReducer = (sum, n) => sum + n

export default (state = initialState, action) => {
  switch (action.type) {
    case MULTIPLE_UPLOAD_HO_FILE.SUCCESS:
      return {
        ...state,
        customerJobInfo: {
          ...state.customerJobInfo,
          pictures: [
            ...(action.payload || []).map(file => ({
              '@id': file['@id'],
              '@type': file['@type'],
              id: file.id,
              url: file.url,
              contentType: file.contentType,
              origin: file.origin,
            })),
            ...state.customerJobInfo.pictures,
          ],
        },
      }
    case GET_JOB.SUCCESS:
    case VALIDATE_RECEIPT.SUCCESS:
    case UPDATE_JOB.SUCCESS:
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          [action.payload['@id']]: {
            ...state.jobsByIri[action.payload['@id']],
            ...action.payload,
          },
        },
      }

    case JOBS_REQ.SUCCESS: {
      const jobsByIri = keyBy(action.payload['hydra:member'], '@id')

      const newJobsCount = {
        ...state.jobsCounts,
        [action.actionParams.status]: action.payload['hydra:totalItems'],
      }

      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          ...jobsByIri,
        },
        jobsCounts: {
          ...newJobsCount,
          ...Object.keys(VIRTUAL_STATUSES).reduce(
            (jobsVirtualStatusCounts, virtualStatus) => ({
              ...jobsVirtualStatusCounts,
              [virtualStatus]: reduce(
                pick(newJobsCount, VIRTUAL_STATUSES[virtualStatus]),
                sumReducer,
              ),
            }),
            {},
          ),
        },
      }
    }
    case SET_ALL_JOBS_IS_LOADING:
      return {
        ...state,
        allJobsIsLoading: action.payload,
      }
    case SET_CURRENT_JOB_ID:
      return {
        ...state,
        currentJobId: action.payload,
      }
    case TRANSITION_INVOICE_JOB.SUCCESS:
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          [state.currentJobId]: {
            ...state.jobsByIri[state.currentJobId],
            invoiceStatus: action.payload.state,
          },
        },
      }
    case GET_RECEIPT_JOB.SUCCESS:
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          [state.currentJobId]: {
            ...state.jobsByIri[state.currentJobId],
            receipt: action.payload,
          },
        },
      }
    case GET_RECEIPT_SIGNATURE.SUCCESS:
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          [state.currentJobId]: {
            ...state.jobsByIri[state.currentJobId],
            receiptSignatureStatus: action.payload.status,
            receiptSignature: action.payload,
          },
        },
      }
    case GET_INVOICE_JOB.SUCCESS:
      return {
        ...state,
        jobsByIri: {
          ...state.jobsByIri,
          [state.currentJobId]: {
            ...state.jobsByIri[state.currentJobId],
            invoice: action.payload,
          },
        },
      }
    case GET_JOBS_CUSTOMER.SUCCESS: {
      return {
        ...state,
        customerAllJobs: action.payload,
      }
    }
    case GET_JOB_CUSTOMER.SUCCESS: {
      let maxDeliveryDate = null
      let minDate = addDays(new Date(), 2)
      if (action.payload.worksite.order.products?.length > 0) {
        maxDeliveryDate = getMaxDate(
          action.payload.worksite.order.products.map(
            product => product?.worksiteDeliveryDate,
          ),
        )
        const limitDate = addDays(new Date(maxDeliveryDate.getTime()), 2)
        minDate =
          dateDiffInDays(minDate, limitDate, false) > 0 ? limitDate : minDate
      }

      return {
        ...state,
        customerJobInfo: {
          ...action.payload,
          minDate: getNextWorkDay(minDate).toISOString(),
          excludedDates: getExcludedDates(maxDeliveryDate),
          maxDeliveryDate,
        },
      }
    }
    case GET_JOB_TOKEN_FIREBASE.SUCCESS:
      return {
        ...state,
        jobTokenFirebase: {
          ...action.payload,
        },
      }
    case SET_CURRENT_GEOLOC_PRO:
      return {
        ...state,
        geolocPro: { ...action.payload },
      }
    case ESTIMATED_HOUR_MAP:
      return {
        ...state,
        estimatedHourMap: action.payload,
      }
    case GET_CHECKING_CONDITION_LIST.SUCCESS:
      return {
        ...state,
        checkConditions: [...action.payload['hydra:member']],
      }
    case GET_PRO_PHONE_NUMBER.SUCCESS:
      return {
        ...state,
        proPhoneNumber:
          action.payload['hydra:member'][0]?.twilioPhoneNumber ?? null,
      }
    case SET_TRACKING_FILL_IN_HO_INFORMATION_FORM_ERROR:
      return {
        ...state,
        isHoFillInInformationFormError: action.payload,
      }
    case UPLOAD_HO_PICTURE_TO_CLOUDINARY.SUCCESS:
      return {
        ...state,
        customerJobInfo: {
          ...state.customerJobInfo,
          pictures: [
            {
              '@id': action.payload['@id'],
              '@type': action.payload['@type'],
              id: action.payload.id,
              url: action.payload.url,
              contentType: action.payload.contentType,
            },
            ...state.customerJobInfo.pictures,
          ],
        },
      }
    case DELETE_JOB_PICTURE.SUCCESS:
      return {
        ...state,
        customerJobInfo: {
          ...state.customerJobInfo,
          pictures: [...state.customerJobInfo.pictures].filter(
            picture => picture.id !== action.actionParams,
          ),
        },
      }
    case GET_RECEIPT_TYPES.SUCCESS:
      return {
        ...state,
        receiptTypes: action.payload,
      }
    case SEND_RESERVES.SUCCESS:
      return {
        ...state,
        openYousignIsLoading: false,
        customerJobInfo: {
          ...state.customerJobInfo,
          reserves: action.payload.reserves,
          reserveType: action.payload.reserveType,
        },
      }
    case GET_RECEIPT_WITH_RESERVES.SUCCESS:
      return {
        ...state,
        customerJobInfo: {
          ...state.customerJobInfo,
          hoReceiptSignableLink: action.payload.members[0].signableLink,
        },
      }
    case OPEN_YOUSIGN_IS_LOADING:
      return {
        ...state,
        openYousignIsLoading: action.payload,
      }
    case HOW_TO_TAKE_PICTURES.SUCCESS:
      return {
        ...state,
        attributes: action.payload,
      }
    case GET_HELP_PHONE.SUCCESS:
      return {
        ...state,
        helpPhone: action.payload,
      }
    default: {
      return state
    }
  }
}
